<template>
  <div class="home-container view404-container" style="text-align: center;">
      <div>
        <div>
          <p class="d1 c-org font-headline" style="font-size: 30px;"> Are you lost at sea?</p>
        </div>
        <div>
          <br>
          <img src="/assets/images/home-cubes.png" alt="Cubes">
        </div>
        <div> 
          <p class="d1 c-org font-headline"> Return <a href="/" target="_blank">home</a> or navigate to <a href="https://2017.halite.io/" target="_blank">Halite II</a>.</p>
        </div>
      </div>
  </div>
</template>

<script>
export default {
      name: 'view404',
      data: function () {
        return {

        }
      },
      mounted: function () {

      },
      methods: {

      }
    }
</script>

<style lang="scss" scoped>

</style>
